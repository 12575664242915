<template>
  <div class="open_item">
    <img :src="appItem.appIcon" alt />
    <h4>{{appItem.appName}}</h4>
    <p>{{$t('createdTime')}}：{{$moment(appItem.createTime).format('YYYY.MM.DD')}}</p>
    <button
      :style="{background: appType[appItem.status].color}"
      @click="getAppItemInfo()"
      >
        {{getOpenStatus}}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    appItem: {
      type: Object
    }
  },
  data () {
    return {
      appType: [
        {
          status: this.$t('openStatus0'),
          color: '#157BEE'
        },
        {
          status: this.$t('openStatus1'),
          color: '#606266'
        },
        {
          status: this.$t('openStatus2'),
          color: '#FD4426'
        },
        {
          status: this.$t('openStatus3'),
          color: '#EAB300'
        }
      ]
    }
  },
  computed: {
    getOpenStatus () {
      console.log(this.$i18n.locale === 'zh')
      return this.$t('openStatus' + this.appItem.status)
    }
  },
  methods: {
    getAppItemInfo () {
      localStorage.setItem('appItemInfo', JSON.stringify(this.appItem))
      this.gotoUrl('created_app')
    }
  }
}
</script>

<style lang='scss'>
.open_item {
  width: calc((100% - 60px) / 4);
  background: #fff;
  margin-top: 15px;
  border-radius: 24px;
  padding: 60px 0 42px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 345px;
  margin-right: 15px;
  animation:mymove 2s 1;
  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-bottom: 18px;
  }
  h4 {
    font-size: 18px;
    color: #303133;
  }
  p {
    font-size: 14px;
    color: #bfc2cc;
  }
  button {
    margin-top: 30px;
    border-radius: 90px;
    height: 46px;
    width: 150px;
    background: #606266;
    font-size: 16px;
    line-height: 46px;
    color: #fff;
    cursor: pointer;
    text-align: center;
  }
  @keyframes mymove {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>
