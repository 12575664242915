var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "open_index index_box" }, [
    _c("div", { staticClass: "open_content" }, [
      _c("div", { staticClass: "open_title" }, [
        _c("u"),
        _c("span", [_vm._v(_vm._s(_vm.$t("openInfo")))]),
        _c("i", { staticClass: "iconfont icon-you" }),
      ]),
      _c(
        "div",
        { staticClass: "open_list" },
        [
          _vm._l(_vm.appList, function (item, index) {
            return _c("appItem", { key: index, attrs: { appItem: item } })
          }),
          _c(
            "div",
            {
              staticClass: "open_created",
              on: {
                click: function ($event) {
                  return _vm.gotoUrl("created_app")
                },
              },
            },
            [
              _vm._m(0),
              _c("button", [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("createdApp")) + "\n        "
                ),
              ]),
            ]
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "add_iconfont" }, [
      _c("i", { staticClass: "iconfont icon-jia" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }