var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "open_item" }, [
    _c("img", { attrs: { src: _vm.appItem.appIcon, alt: "" } }),
    _c("h4", [_vm._v(_vm._s(_vm.appItem.appName))]),
    _c("p", [
      _vm._v(
        _vm._s(_vm.$t("createdTime")) +
          "：" +
          _vm._s(_vm.$moment(_vm.appItem.createTime).format("YYYY.MM.DD"))
      ),
    ]),
    _c(
      "button",
      {
        style: { background: _vm.appType[_vm.appItem.status].color },
        on: {
          click: function ($event) {
            return _vm.getAppItemInfo()
          },
        },
      },
      [_vm._v("\n      " + _vm._s(_vm.getOpenStatus) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }