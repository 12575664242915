<template>
  <div class="open_index index_box">
    <div class="open_content">
      <div class="open_title">
        <u></u>
        <span>{{ $t("openInfo") }}</span>
        <i class="iconfont icon-you"></i>
      </div>
      <div class="open_list">
        <appItem
          v-for="(item, index) in appList"
          :key="index"
          :appItem="item"
        />
        <div class="open_created" @click="gotoUrl('created_app')">
          <div class="add_iconfont">
            <i class="iconfont icon-jia"></i>
          </div>
          <button>
            {{ $t("createdApp") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appItem from "./appItem";
import { getProjectList } from "@/api/api";
export default {
  data() {
    return {
      appList: [],
    };
  },
  created() {
    localStorage.removeItem("appItemInfo");
  },
  methods:{
    getAppList(){
    const userInfo = this.$store.state.userData;
    const params = {
      userType: userInfo.register,
      metaId: userInfo.metaId,
    };
    params[userInfo.register] = userInfo.register === "phone" ? userInfo.phone : userInfo.email;
     getProjectList(params).then((res) => {
      this.appList = res
       console.log("appList", this.appList);
    })
    }
   
  },
  components: {
    appItem,
  },
  mounted(){
   this.getAppList()
  }
};
</script>

<style lang="scss">
.open_content {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  margin-top: 75px;
  .open_title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    u {
      width: 12px;
      height: 12px;
      background: #eab300;
      border-radius: 50%;
    }
    span {
      font-size: 18px;
      margin: 0 7px;
      color: #fff;
    }
    .icon-you {
      font-size: 14px;
      color: #fff;
      margin-top: 2px;
    }
  }
  .open_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .open_created {
      width: calc((100% - 60px) / 4);
      background: #fff;
      border-radius: 24px;
      padding: 60px 0 42px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 345px;
      margin-right: 15px;
      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
        margin-bottom: 18px;
      }
      h4 {
        font-size: 18px;
        color: #303133;
      }
      p {
        font-size: 14px;
        color: #bfc2cc;
      }
      button {
        margin-top: 30px;
        border-radius: 90px;
        height: 46px;
        width: 150px;
        background: #606266;
        font-size: 16px;
        line-height: 46px;
        color: #fff;
        cursor: pointer;
        text-align: center;
      }
    }
    .open_created {
      justify-content: space-between;
      margin-top: 15px;
      animation: mymove 2s 1;
      .add_iconfont {
        width: 100px;
        height: 100px;
        margin-bottom: 18px;
        line-height: 100px;
        background: #e7e8e9;
        border-radius: 12px;
        text-align: center;
      }
      .icon-jia {
        font-size: 24px;
        color: #909399;
      }
    }
  }
  @keyframes mymove {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 768px) {
    .open_item {
      width: calc((100% - 20px) / 2);
      margin-right: 0px;
    }
    .open_list {
      justify-content: space-around;
      .open_created {
        width: calc((100% - 20px) / 2);
        margin-right: 0px;
      }
    }
  }
}
</style>
